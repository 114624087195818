import './schedule-form.css'
import { useDays } from '../../hooks/useDays';
import { useAdminValue } from '../../context/AdminContext';

export default function ScheduleForm({
    name,
    cel,
    date,
    hour,
    service,
    professional,
    setName,
    setCel,
    handleSubmit,
    loading,
    user,
    noDayOffBarber,
    exclusiveHours,
    handleDateChange,
    handleHourChange,
    handleCelChange,
    handleProfessionalChange,
    handelServiceChange,
    isAdmin
}) {

    const todaysDateObject = new Date()
    const todaysDate = todaysDateObject.toISOString().split('T')[0]
    const todaysDatePlusSeven = new Date(todaysDateObject.setDate(todaysDateObject.getDate() + 7)).toISOString().split('T')[0]

    return (
        <>
            <section className="schedule-form-section">

                <h1 className="form-title">Marque seu horário</h1>

                <form className="appointment-form"
                    onSubmit={handleSubmit}
                >
                    <label>
                        <span>Nome Completo</span>
                        {
                            isAdmin ? (
                                <input
                                    type="text"
                                    name='nome'
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder='Digite seu nome completo'
                                    value={name}
                                    required
                                />
                            ) : (
                                user ? (
                                    <input
                                        type="text"
                                        name='nome'
                                        onChange={(e) => setName(e.target.value)}
                                        placeholder='Digite seu nome completo'
                                        value={name}
                                        required
                                        disabled
                                    />
                                ) : (
                                    <input
                                        type="text"
                                        name='nome'
                                        onChange={(e) => setName(e.target.value)}
                                        placeholder='Digite seu nome completo'
                                        value={name}
                                        required
                                    />
                                ))}

                    </label>
                    <label>
                        <span>Celular</span>
                        {
                            isAdmin ? (
                                <input
                                    type="text"
                                    name='celular'
                                    placeholder='(19) 99323-2332'
                                    // onChange={(e) => setCel(e.target.value)}
                                    onChange={(e) => handleCelChange(e)}
                                    value={cel}
                                    required
                                />
                            ) : (
                                user ? (
                                    <input
                                        type="text"
                                        name='celular'
                                        placeholder='(19) 99323-2332'
                                        onChange={(e) => setCel(e.target.value)}
                                        // onChange={(e) => handleCelChange(e)}
                                        value={cel}
                                        required
                                        disabled
                                    />
                                ) : (
                                    <input
                                        type="text"
                                        name='celular'
                                        placeholder='(19) 99323-2332'
                                        // onChange={(e) => setCel(e.target.value)}
                                        onChange={(e) => handleCelChange(e)}
                                        value={cel}
                                        required
                                    />
                                ))}
                    </label>
                    <label>
                        <span>Data</span>
                        <input
                            type="date"
                            name='date'
                            min={todaysDate}
                            max={todaysDatePlusSeven}
                            onChange={(e) => handleDateChange(e)}
                            // onChange={(e) => setDate(e.target.value)}
                            value={date}
                            required
                        />
                    </label>
                    <div className="service">
                        <label>
                            <span>Serviço</span>
                            {date === "" ? (
                                <select>
                                    <option value="" defaultChecked>Selecione</option>
                                </select>
                            ) : (
                                <select
                                    onChange={(e) => handelServiceChange(e)}
                                    value={service}
                                    required
                                >
                                    <option value="" defaultChecked>Selecione</option>
                                    <option value="Cabelo">Cabelo</option>
                                    <option value="Barba">Barba</option>
                                    <option value="Cabelo e Barba">Cabelo e Barba</option>
                                    {/* <option value="Prótese + Corte">Prótese + Corte</option>
                                    <option value="Manutenção da Prótese">Manutenção da Prótese</option> */}
                                </select>
                            )}

                        </label>
                        <label>
                            <span>Barbeiro</span>
                            {date === '' ? (
                                <select>
                                    <option value="" defaultChecked>Selecione</option>
                                </select>
                            ) : (
                                <select
                                    onChange={(e) => handleProfessionalChange(e)}
                                    value={professional}
                                    required
                                >
                                    <option value="" defaultChecked>Selecione</option>
                                    {noDayOffBarber.map((e, i) => (
                                        <option key={i} value={e}>{e}</option>
                                    ))}

                                    {/* <option value="Donizete">Donizete</option> */}
                                </select>
                            )}

                        </label>
                    </div>
                    <label>
                        <span>Horário</span>
                        {professional === "" ? (
                            <select>
                                <option value="" defaultValue=''>Selecione</option>
                            </select>
                        ) : (
                            <select
                                onChange={(e) => handleHourChange(e)}
                                value={hour[0]}
                                required
                            >
                                <option value="" defaultValue=''>Selecione</option>
                                {exclusiveHours.map((hour, i) => (
                                    <option key={i} value={hour}>{hour}</option>
                                ))}
                            </select>
                        )}

                    </label>
                    {loading ? (
                        <button disabled type='submit'>Aguarde</button>
                    ) : (
                        <button type='submit'>AGENDAR</button>
                    )}
                </form>
            </section >
        </>
    )
}